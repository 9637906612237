import { RootStores } from '@/store/core/RootStore';
import { observable } from 'mobx';

export default class StoreConstructor {
  @observable stores: RootStores;

  constructor(stores: RootStores) {
    this.stores = stores;
  }
}
