import { ApiService } from '@/api/services/core';
import { baseApi } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';

import { ITaxPayerInfo, IFNModel } from '@/utils/types';

export class TaxPayerService extends ApiService {
  public getTaxPayerByTin(tin: string) {
    return baseApi.get<ITaxPayerInfo>(ENDPOINTS.taxpayer.getByTin(tin));
  }

  public addFiscalMemory(tin: string, fiscalMemory: IFNModel) {
    return baseApi.put<string>(
      ENDPOINTS.taxpayer.addFiscalMemory(tin),
      fiscalMemory,
    );
  }
}
