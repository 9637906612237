import { action, observable, computed } from 'mobx';
import { RootStores } from '@/store/core/RootStore';
import { UserState } from '@/store/types';
import { Credentials } from '@/api/services/userService/types';
import StoreConstructor from '@/store/core/StoreConstructor';
import services from '@/api/services';
import { i18n } from '@/i18n';

import {
  ControlsCollection,
  FormControl,
  FormGroup,
  requiredValidator,
  minLengthValidator,
  wrapperSequentialCheck,
  ValidationEvent,
  ValidationEventTypes,
} from '@quantumart/mobx-form-validation-kit';

import { LegalPersonType } from '@/api/services/userService/types';
import { AxiosError } from 'axios';
// import { Roles } from '@/api/services/userService/types';

interface AutoComleteList {
  label: string;
  value: string | number;
}

interface SearchInput extends ControlsCollection {
  search: FormControl<string>;
}

interface NewFmModule extends ControlsCollection {
  fnNumber: FormControl<string>;
  validationCode: FormControl<string>;
}

export default class User extends StoreConstructor {
  @observable key: UserState['key'] = null;
  @observable info: UserState['info'] = null;
  @observable error: UserState['error'] = null;
  @observable taxNumber = '7199954823';
  @observable legalPersonId = 1;
  @observable demoKey: any = '';

  @observable type: LegalPersonType = LegalPersonType.Entity;
  @observable taxAuthorityDepartmentsList: AutoComleteList[] = [];
  @observable legalPersonActivitiesList: AutoComleteList[] = [];

  public form: FormGroup<SearchInput>;
  public newFmModule: FormGroup<NewFmModule>;

  constructor(stores: RootStores) {
    super(stores);
    if (localStorage.getItem('demoKey'))
      this.demoKey = localStorage.getItem('demoKey');
    let key: UserState['key'] = null;
    const lsKey = localStorage.getItem('auth');
    if (lsKey) key = JSON.parse(lsKey);
    this.setKey(key);

    this.form = new FormGroup<SearchInput>({
      search: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator(i18n.tc('fm-manager.tin.errors.required')),
            minLengthValidator(14),
            async (
              control: FormControl<string>,
            ): Promise<ValidationEvent[]> => {
              const v = String(control.value);
              //eslint-disable-next-line
              if (v.length < 14 || !v.match(/^\d+$/)) {
                return [
                  {
                    message: i18n.tc('fm-manager.tin.errors.pattern'),
                    type: ValidationEventTypes.Error,
                  },
                ];
              }
              return [];
            },
          ]),
        ],
      }),
    });

    this.newFmModule = new FormGroup<NewFmModule>({
      fnNumber: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator(
              i18n.tc('fm-manager.add-fm-dialog.fm-number.errors.required'),
            ),
            async (
              control: FormControl<string>,
            ): Promise<ValidationEvent[]> => {
              const v = String(control.value);
              //eslint-disable-next-line
              if (v.length < 16 || v.length > 16 || !v.match(/^\d+$/)) {
                return [
                  {
                    message: i18n.tc(
                      'fm-manager.add-fm-dialog.fm-number.errors.format',
                    ),
                    type: ValidationEventTypes.Error,
                  },
                ];
              }
              return [];
            },
          ]),
        ],
      }),
      validationCode: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator(
              i18n.tc(
                'fm-manager.add-fm-dialog.validation-code.errors.required',
              ),
            ),
            async (
              control: FormControl<string>,
            ): Promise<ValidationEvent[]> => {
              const v = String(control.value);
              //eslint-disable-next-line
              if (v.length < 3 || v.length > 3 || !v.match(/^\d+$/)) {
                return [
                  {
                    message: i18n.tc(
                      'fm-manager.add-fm-dialog.validation-code.errors.format',
                    ),
                    type: ValidationEventTypes.Error,
                  },
                ];
              }
              return [];
            },
          ]),
        ],
      }),
    });
  }

  @computed get hasError() {
    return !!this.error;
  }

  @computed get isLoggedIn() {
    return !!this.key;
  }

  @computed get refreshToken() {
    return this.key?.refreshToken;
  }

  @computed get token() {
    return this.key?.accessToken;
  }

  @action setError(error: UserState['error'] = null) {
    this.error = error;
  }

  @action setKey(key: UserState['key']) {
    localStorage.setItem('auth', JSON.stringify(key));
    this.key = key;
  }

  @action removeKey() {
    localStorage.removeItem('auth');
    this.key = null;
  }

  @action setInfo(info: UserState['info']) {
    this.info = info;
  }

  @action.bound async LOGIN(props: Credentials) {
    try {
      const { data } = await services.user.login(props);
      this.setError();
      this.setKey(data);
    } catch (e: any) {
      this.setError({
        message: i18n.tc('sign-in.form.errors.incorrect'),
      } as AxiosError);
      throw new Error(e);
    }
  }

  @action.bound async LOGOUT() {
    this.removeKey();
    localStorage.removeItem('demoKey');
    this.setInfo(null);
  }

  @action.bound async GETUSER() {
    try {
      const { data } = await services.user.getCurrentUser();

      //TODO: add navigate
      // if (!data.roles.includes(Roles.Distributor)) {
      //   await this.$router.navigate(['/sign-in']);
      // }
      this.setError();
      this.setInfo(data);
    } catch (e: any) {
      console.warn(e);
    }
  }
}
