
import { defineComponent } from 'vue';

const DEFAULT_LAYOUT = 'default';

export default defineComponent({
  computed: {
    layout(): string {
      return (this.$route.meta?.layout || DEFAULT_LAYOUT) + '-layout';
    },
  },
});
