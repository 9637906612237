import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ruLocale from '@/locales/ru.json';
import enLocale from '@/locales/en.json';

// @ts-ignore
import ruKitLocale from '@dtt/ui-kit/src/locale/lang/ru.json';
// @ts-ignore
import enKitLocale from '@dtt/ui-kit/src/locale/lang/en.json';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'en',
  fallbackLocale: 'en',
  messages: {
    ru: { ...ruLocale, ...ruKitLocale },
    en: { ...enLocale, ...enKitLocale },
  },
});

function setI18nLanguage(lang: string) {
  i18n.locale = lang;

  switch (lang) {
    case 'ru':
      document.title = 'Фискальный менеджер';
      break;
    case 'en':
      document.title = 'Fiscal manager';
      break;
    default:
      break;
  }

  localStorage.setItem('lang', lang);
  return lang;
}

export { i18n, setI18nLanguage };
