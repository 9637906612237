import { RouteConfig } from 'vue-router';
import AuthRoutes from './auth';
const Home = () => import('@/views/home.vue');

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'index',
    meta: { layout: 'default', public: false },
    component: Home,
  },
  AuthRoutes,
];

export default routes;
