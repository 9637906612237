import { RouteConfig } from 'vue-router';
import stores from '@/store/core/RootStore';

const AuthView = () => import('@/views/auth/auth.vue');

const AuthLoginView = () => import('@/views/auth/login/login.vue');
const AuthLoginIndex = () => import('@/views/auth/login/index.vue');

export default {
  path: '/auth',
  component: AuthView,
  children: [
    {
      path: 'login',
      component: AuthLoginView,
      children: [
        {
          path: '',
          name: 'auth-login-index',
          meta: { layout: 'auth', public: true },
          component: AuthLoginIndex,
        },
      ],
    },
  ],
} as RouteConfig;
