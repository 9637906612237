import { createUrl } from '@/utils';

const auth = createUrl('web-control');
const api = createUrl('api');

const login = auth('login');
const refresh = api('api/auth/refresh');
const user = auth('me');

export const authEndpoints = {
  login,
  refresh,
  user,
};
