export const createUrl = (basePath: string) => (path: string) =>
  `${basePath}${'/' + path}`;

/**
 *
 * @param number Number
 * @param txt for example Russian: ['Рубль', 'Рубля', 'Рублей', 'Рубль'], English: ['Ruble', 'Rubles', 'Rubles', 'Rubles']
 * @param cases
 */
export const sklonenie = (
  number: number,
  txt: string[],
  cases = [2, 3, 1, 1, 1, 2],
) =>
  txt[
    number === 1
      ? 0
      : number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];

export const getFormatterNumber = (regNumber: string, prefix = '№') => {
  if (!regNumber) {
    return '';
  }
  const parts = [
    regNumber.substr(0, 4),
    regNumber.substr(4, 4),
    regNumber.substr(8, 4),
    regNumber.substr(12, 4),
  ];
  const number = parts.join(' ');
  return prefix ? `${prefix} ${number}` : `${number}`;
};
