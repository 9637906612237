import { createUrl } from '@/utils';
const user = createUrl('user');

const recovery = user('recovery');
const recoveryPass = user('recovery/password');

export const userEndpoints = {
  recovery,
  recoveryPass,
};
