import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/router/routes';
import stores from '@/store/core/RootStore';
import Component from 'vue-class-component';

Vue.use(VueRouter);

Component.registerHooks(['beforeRouteEnter']);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const auth = localStorage.getItem('auth');
  if (!to.meta?.public && (!auth || !JSON.parse(auth))) {
    next({ name: 'auth-login-index' });
  } else next();
});

router.beforeEach(async (to, from, next) => {
  if (to.query.key) {
    localStorage.setItem('demoKey', to.query.key as string);
    stores.user.demoKey = localStorage.getItem('demoKey');

    if (stores.user.demoKey !== 'hxygKbvT' && to.name !== 'auth-login-index') {
      next({ name: 'auth-login-index' });
    } else {
      if (!to.query.user && from.query.user) {
        to.query.user = from.query.user;
        next({
          path: to.path,
          query: to.query,
          params: to.params,
        });
      } else {
        if (
          stores.user.demoKey === 'hxygKbvT' &&
          to.name === 'auth-login-index'
        ) {
          const data = {
            login: process.env.VUE_APP_DEMO_LOGIN,
            password: process.env.VUE_APP_DEMO_PASS,
          };
          stores.user.LOGIN(data).then(() => {
            setTimeout(() => {
              next({
                path: '/',
              });
            }, 1500);
          });
        }
        next();
      }
    }
  } else {
    const key = localStorage.getItem('demoKey');

    if (key === 'hxygKbvT' && to.name === 'auth-login-index') {
      const data = {
        login: process.env.VUE_APP_DEMO_LOGIN,
        password: process.env.VUE_APP_DEMO_PASS,
      };
      stores.user.LOGIN(data).then(() => {
        next({
          path: '/',
        });
      });
    }

    next();
  }
});

export default router;
