import { createUrl } from '@/utils';

const api = createUrl('web-control/api/distributor/taxpayer');

const getByTin = (tin: string) => api(`${tin}`);
const addFiscalMemory = (tin: string) => api(`${tin}/assign`);
export const taxPayerEndpoints = {
  getByTin,
  addFiscalMemory,
};
